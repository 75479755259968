import axios from 'axios'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

import store from '@/store'

function setPopPosition(w: number, h: number): string {
  let popStyleText = ''
  const windowWidth: number = window.innerWidth
  const windowHeight: number = window.innerHeight
  let popPositionLeft = ''
  let popPositionTop = ''
  if (windowWidth < w) {
    popPositionLeft = '0'
  } else {
    popPositionLeft = (windowWidth - w) / 2 + 'px'
  }
  if (windowHeight < h) {
    popPositionTop = '0'
  } else {
    popPositionTop = (windowHeight - h) / 2 + 'px'
  }

  popStyleText += 'left: ' + popPositionLeft + ';'
  popStyleText += 'top: ' + popPositionTop + ';'
  popStyleText += 'width: ' + w + 'px;'
  popStyleText += 'height: ' + h + 'px;'
  return popStyleText
}
export async function postAxios(
  url: string,
  param: object | null,
  loadingYn?: boolean | null
): Promise<any> {
  console.log('====================' + url + '==========param:')
  console.log(param)
  if (loadingYn === null || loadingYn === undefined) {
    loadingYn = true
  }
  const headers = {
    'Content-type': 'application/json; text/html;charset=utf-8;',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,POST,PATCH,PUT,DELETE,OPTIONS',
    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
    'Access-Control-Allow-Credentials': true
  }
  let res = null
  if (loadingYn) {
    showAxiosLoading(true)
  }
  try {
    res = await axios
      .post(url, param, { headers: headers })
      .catch(function (error: any) {
        if (error.response) {
          // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
          console.log(error.response.data)
          console.log(error.response.status)
          console.log(error.response.headers)
        } else if (error.request) {
          // 요청이 이루어 졌으나 응답을 받지 못했습니다.
          // `error.request`는 브라우저의 XMLHttpRequest 인스턴스 또는
          // Node.js의 http.ClientRequest 인스턴스입니다.
          console.log(error.request)
        } else {
          // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
          console.log('Error', error.message)
        }
        console.log(error.config)
      })
  } catch (error) {
    console.log(error)
  } finally {
    showAxiosLoading(false)
    // commonMethods.showAxiosLoading(false)
  }
  console.log('====================' + url + '==========result:')
  console.log(res)
  if (!res) {
    return
  }
  if (
    !res ||
    !res.data ||
    res.data === '' ||
    res.data === "<script>top.location.href='/';</script>"
  ) {
    // store.dispatch('OA_USER/AC_USER_CLEAR', '')
  }
  return res
}
function showAxiosLoading(showYn: boolean) {
  const loadingCompo = document.getElementById('axiosShadow')
  if (showYn) {
    /*  if (loadingCompo.style.display === 'flex') */
    if (loadingCompo) loadingCompo.style.display = 'flex'
    // setTimeout(() => {
    //   if (loadingCompo) loadingCompo.style.display = 'none'
    // }, 3000)
  } else {
    if (loadingCompo) loadingCompo.style.display = 'none'
  }
}
function changeDateFormat(
  date: number | Date,
  mustTimeShowYn: boolean,
  onlyShowYMDYN: boolean,
  onlyShowMMDD: boolean
): string {
  // var compareDate = new Date(Number(date))
  const compareDate = new Date(date)
  const toDate = new Date()
  let format = ''

  if (onlyShowYMDYN) {
    // 년원일로 무조건 볼 수 있게
    format = 'YYYY/MM/DD'
    return dayjs(compareDate).format(format)
  } else if (onlyShowMMDD) {
    format = 'MM/DD'
    return dayjs(compareDate).format(format)
  }
  if (dayjs(compareDate).format('YYYY') === dayjs(toDate).format('YYYY')) {
    if (dayjs(compareDate).format('MM') === dayjs(toDate).format('MM')) {
      if (dayjs(compareDate).format('DD') === dayjs(toDate).format('DD')) {
        // 년도 월 일 이 같으면 만든 시간, 분
        format = 'HH:mm'
        // format = 'HH시 mm분'
      } else {
        // 같은 년도, 월이 같으면
        /* format = 'MM/DD HH:mm' */
        format = 'MM/DD'
        // format = 'MM월 DD일'
      }
    } else {
      // 년도만 같으면
      format = 'MM/DD'
      // format = 'MM월 DD일'
    }
  } else {
    format = 'YYYY/MM/DD'
  }
  // format = 'YYYY/MM/DD'
  if (mustTimeShowYn) format = 'YYYY-MM-DD HH:mm'
  else if (mustTimeShowYn && format !== 'HH:mm') format += ' HH:mm'
  // return dayjs(compareDate).add(9, 'hour').format(format)
  return dayjs(compareDate).add(9, 'hour').format(format)
}
function convertToPlain(html: string) {
  if (!html) return '의견 없음'
  // Create a new div element
  const tempDivElement = document.createElement('div')
  // Set the HTML content with the given value
  tempDivElement.innerHTML = html
  // Retrieve the text property of the element
  return tempDivElement.textContent || tempDivElement.innerText || ''
}

function checkUserAuth(authType: string) {
  let returnAuthYn = false
  if (authType !== 'GV' && authType !== 'SV') {
    returnAuthYn = true
  }
  return returnAuthYn
}
function browserDetect() {
  const userAgent = navigator.userAgent
  let browserName

  if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = 'chrome'
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = 'firefox'
  } else if (userAgent.match(/safari/i)) {
    browserName = 'safari'
  } else if (userAgent.match(/opr\//i)) {
    browserName = 'opera'
  } else if (userAgent.match(/edg/i)) {
    browserName = 'edge'
  } else {
    browserName = 'No browser detection'
  }
  return browserName
}
export default {
  install(Vue: any) {
    Vue.config.globalProperties.$gPostAxios = postAxios
    Vue.config.globalProperties.$setPopPosition = setPopPosition
    Vue.config.globalProperties.$changeDateFormat = changeDateFormat
    Vue.config.globalProperties.$gShowAxiosLoading = showAxiosLoading
    Vue.config.globalProperties.$gConvertToPlain = convertToPlain
    Vue.config.globalProperties.$checkUserAuth = checkUserAuth
    Vue.config.globalProperties.$browserDetect = browserDetect
  }
}

// export { setPopPosition, changeDateFormat, postAxios }
