<template>
  <div class="JOCommonBtnArea">
    <gBtn
      style="min-width: 80px"
      :pPointYn="btn.pointYn"
      :pBtnTitle="btn.btnTitle"
      @click="btn.onClickCallBack ? btn.onClickCallBack() : ''"
      v-for="(btn, index) in pBtnList"
      :key="index"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    pBtnList: {} as any,
    pEmitEvent: Function
  }
})
</script>
<style lang="scss" scoped>
.JOCommonBtnArea {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px -7px 9px -9px #00000036;

  .btnStyle + .btnStyle {
    margin-left: 10px;
  }
}

// galuxy fold
@media (max-width: 280px) {
  .JOCommonBtnArea {
    .btnStyle + .btnStyle {
      margin-left: 5px !important;
    }
  }
}
</style>
