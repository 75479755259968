<template>
  <div v-if="pTabList" class="JOActiveBarWrap" >
    <div class="fl textLeft JOActiveBarPWrap" v-for="(tab, index) in pTabList" @click="switchtab(index, tab)" :key="index" ref="tab">
      <p :style="activebarWidth" class="tabItem fontBold" :class="{commonColor: index === activetab, lightGray: index !== activetab}" v-html="tab.dispName"></p>
    </div>
    <!-- <div class="w100P " style="position: absolute;  top: 31px; background: #aaa;  height: 1px; border-radius: 3px; left:0"></div> -->
    <div class="JOActiveBar fl" ref="activeBar" :style="activebarWidth"></div>
  </div>
  <!-- <div ref="tcon" class="tabcontainer">
        <transition :name="transition" v-for="(tab, index) in pTabList" :key="index">
            <div class="tabpane" v-if="index === activetab">
            </div>
        </transition>
    </div> -->
</template>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    pTabList: {
      default: [] as object[]
    } as any,
    pChangeTab: Function,
    pSelectedTab: {} as any
  },
  created () {
    this.activetab = this.pSelectedTab
  },
  data () {
    return {
      transition: 'slide-next',
      activetab: 0,
      tabwidth: 4,
      touch: { sx: null, sy: null, st: null, ex: null, ey: null, et: null } as any,
      selectedTabName: ''
    }
  },
  methods: {
    switchtab (n : string | number, tab : any) {
      if (tab) {
        if (tab.dispName !== undefined && tab.dispName !== null && tab.dispName !== '') {
          this.selectedTabName = tab.dispName.trim()
        }
        if (this.pChangeTab) {
          this.pChangeTab(tab.tabType)
        }
      }
      this.activetab = Number(n)
    }
  },
  computed: {
    pointer () {
      if (window.PointerEvent) return true
      else return false
    },
    activebarWidth () {
      return {
        '--tabwidth': this.tabwidth + 'rem',
        '--transform': 'translateX(' + (this.activetab * this.tabwidth * 1) + 'rem' + ')'
      }
    }
  }
})
</script>

<style >
/* background: #e9e9e9 */
 /* background: #e4e4e463; */
 .JOActiveBarWrap {
  width: 100%;
  min-height: 30px;
  position: relative;
 }
 .JOActiveBarPWrap {
  white-space: nowrap;
  flex:1
 }
.activeSearchInput{
  border: none;
  background: #e9e9e9;
  height: 90%;
  float: left;
  width: 6%;
  border-radius: 12px;
  padding: 0.4rem;
  padding-right: 3rem;
  box-sizing: border-box
}
.JOActiveBar{
  min-width: var(--tabwidth);
  top: 33px;
  transition: 0.5s ease;
  transform: var(--transform);
  position: absolute;
  background: #0b4180;
  height: 3px;
  border-radius: 3px;
  left:0
}
.tabs {
  display: inline-table;
  position: relative;
  height: 100px;
  overflow-x: scroll;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}
.tabs::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
}
.tabItem {
  display: flex;
  align-items: center;
  float: left;
  justify-content: center;

  /* justify-content: flex-start; */
  cursor: pointer;
  text-transform: uppercase;
  width: var(--tabwidth);
  margin: 0 auto;
  white-space: nowrap;
}

.slider {
  position: absolute;
  bottom: 0px;
  height: 4px;
  width: var(--tabwidth);
  /* width: 130px; */
  background: black;
  transition: 0.5s ease;
}
.mWidth{
  width: 4.5rem !important;
}
.tabcontainer {
  height: 900px;
  position: relative;
  min-height: 100%;
  width: 100%;
  touch-action: pan-y;
}

.tabpane {
  /* position: absolute;
  width: 100%; */
  font-size: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
