<template>
  <div class="JOConfirmPopWrap" :style="mPositionText">
    <div class="JOCommonConfirmHeader commonHeaderText">
      <p v-html="pPopHeaderText"></p>
      <div v-if="pCloseFn" class="closeFn">
        <img
          src="resource/images/common/JOCloseIcon.svg"
          alt="닫기"
          @click="pCloseFn"
        />
      </div>
    </div>
    <div
      class="JOCommonConfirmBody commonNormalText textCenter"
      v-html="pPopBodyText"
    ></div>
    <div class="JOCommonConfirmFooter">
      <gPopFooter v-if="pBtnList && pBtnList.length > 0" :pBtnList="pBtnList" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
// import { setPopPosition } from '@/assets/ts/JOCommon'
export default defineComponent({
  props: {
    pPopHeaderText: {},
    pPopBodyText: {},
    pTimerFunction: Function,
    pBtnList: [] as any,
    pCloseFn: Function as any
  },
  data() {
    return {
      mPositionText: '',
      mPopSize: { popWidth: 330, popHeight: 250 }
    }
  },
  mounted() {
    this.mPositionText = this.$setPopPosition(
      this.mPopSize.popWidth,
      this.mPopSize.popHeight
    )
    window.addEventListener('resize', () => {
      this.mPositionText = this.$setPopPosition(
        this.mPopSize.popWidth,
        this.mPopSize.popHeight
      )
    })
    if (this.pTimerFunction) {
      const timerFunction = this.pTimerFunction
      setTimeout(() => {
        timerFunction()
      }, 2000)
    }
  }
})
</script>

<style lang="scss" scoped>
.JOConfirmPopWrap {
  position: fixed;
  z-index: 9999;
  background: white;
  box-shadow: rgb(68 68 68) 0px 0px 4px 0px;
  border: 2px solid rgb(157, 157, 157);
  border-radius: 10px;
}
.JOCommonConfirmHeader {
  position: relative;
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #ccc;
  p {
    float: left;
    width: 100%;
    text-align: center;
  }
  .closeFn {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 0%;
    right: 4%;
    cursor: pointer;
    img {
      width: 100%;
    }
  }
}
.JOCommonConfirmBody {
  width: 100%;
  padding: 10px 20px;
  min-height: calc(100% - 60px - 60px);
  float: left;
  /* text-align: center; */
  padding-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.JOCommonConfirmFooter {
  width: 100%;
  height: 60px;
  float: left;
}
</style>
