<template>
  <div class="JOButtonWrap">
    <gBtn
      v-for="(item, index) in pBtnList"
      :key="index"
      style="padding: 5px 10px"
      :pBtnTitle="item.btnTitle"
      :pPointYn="item.pointYn"
      @click="item.onClickFn"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

// 넘겨받을 props 형태
/**
  [
    {btnTitle: string, pointYn: boolean ,onClickFn: Function},
    {btnTitle: string, pointYn: boolean ,onClickFn: Function},
  ]
*/

export default defineComponent({
  props: {
    pBtnList: Array as any
  }
})
</script>

<style scoped>
.JOButtonWrap {
  display: flex;
  justify-content: center;
  align-items: center;
}
.JOButtonWrap > div:nth-child(1) {
  margin-right: 10px;
}
</style>
