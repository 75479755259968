<template>
  <div class="w100P h100P fl JOErrorWrap">
    <!-- <img class="mBottom-10 fl" style="margin-top: 60px; height: 150px;" src="@/assets/images/common/JOCommonCharacter.png" />
      <div class="w100P fl mTop-10">죄송합니다. 요청하신 페이지를 찾을 수 없습니다.<br>요청한 url을 다시 한번 확인해주세요.<br><br>감사합니다.</div> -->
      <!-- <p class="fl w100P commonTitleText mBottom-20 textCenter">서비스가 준비중 입니다.</p> -->
    <div class="w100P fl" style="min-height: 240px;">
      <div class="JOMainProfile">
        <!-- <p class="w100P fl mBottom-10 commonTitleText">{{mErrorStatus}} 오류</p> -->
        <div v-if="mErrorStatus === '404' || mErrorStatus === 404" class="w100P fl commonTitleText" v-html="mSystemMsg"></div>
        <div v-else class="w100P fl commonTitleText">
          <p v-if="mErrorStatus === ''">원활한 서비스를 위하여<br>점검과 필요한 패치를 진행중입니다.</p>
          <p v-else>원활한 서비스를 위하여<br>점검과 필요한 패치를 진행중입니다. ({{ mErrorStatus }})</p>
          <p class="commonSubTitleText" style="font-weight: normal;"><br>잠시만 기다려 주세요. 감사합니다.</p>
        </div>
      </div>
      <div class="w100 JOErrorBox">
        <img src="@/assets/images/main/JOMainBubble.png" class="JOBubbleImg" alt="">
        <img class="mLeft-05 JOCharacterImg" src="@/assets/images/common/JOCommonCharacter.png" alt="">
      </div>
    </div>
    <div class="w100P fl mTop-30 commonNormalText JOGoAskWrap">
      <span class="mRight-10 JOSpan">궁금한 사항이 있으신가요?</span>
      <span class="textUnderLine fontBold cursorP JOSpan" @click="$router.push('/about')">고객센터 바로가기 ></span>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  data () {
    return {
      // mSystemMsg: '오류가 발생했습니다.<br>잠시 후 다시 시도해주세요.<br>조사 서비스 패치시간: 19시 ~ 20시'
      mErrorStatus: '' as any,
      mSystemMsg: ''
    }
  },
  created () {
    if (this.$route.query && this.$route.query.errorStatus) {
      this.mErrorStatus = this.$route.query.errorStatus
      if (this.mErrorStatus === '404' || this.mErrorStatus === 404) {
        this.mSystemMsg = '<p>페이지를 찾을 수 없습니다. (404)</p><p class="commonSubTitleText" style="font-weight: normal;"><br>올바른 URL을 입력하였는지 확인해주세요.<br>감사합니다.</p>'
      }
    }
  }
})
</script>
<style scoped>
.JOGoAskWrap {
  display: flex; justify-content: center;
}
.JOBubbleImg {
  position: absolute; top: -4px; left: 60%;
}
.JOCharacterImg {
  position: absolute; top: 20px; left: calc(50% - 50px); height: 100px; width: 100px;
}
.JOErrorBox {
  min-height:150px; display: flex; align-items: flex-end; justify-content: space-between; position: relative;
}
.JOErrorWrap {
  display: flex; flex-direction: column; justify-content: center;
}
.JOMainProfile {
  width: 95%; text-align: center; margin: auto; padding: 20px; min-height: 150px; min-width: 250px; max-width: 600px; background-color: #f1f1f1; display: flex; flex-direction: column; justify-content: center; align-items: center; border-radius: 30px; border: 4px solid #0b4180;
}
@media screen and (max-width: 720px) {
  .JOMainDescWrap {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
}
@media screen and (max-width: 400px) {
  .JOSpan {
    display: block !important;
  }
  .JOGoAskWrap {
    flex-direction: column;
    align-items: center;
  }
}
</style>
