import { createApp } from 'vue'
import App from './JOTotalMain.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios'
import BootstrapVue3 from 'bootstrap-vue-3'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import gInput from '@/components/common/JOCommonInput.vue'
import gSelectBtn from '@/components/common/JOCommonSelectBtn.vue'
import gPreviewImgPop from '@/components/common/popup/JOCommonImgPreviewPop.vue'
import gConfirmPop from '@/components/common/popup/JOCommonConfirmPop.vue'
import gPopHeader from '@/components/common/JOCommonPopHeader.vue'
import gPopFooter from '@/components/common/JOCommonPopFooter.vue'
import gBtn from '@/components/common/JOCommonBtn.vue'
import gPagenation from '@/components/common/JOCommonPagenation.vue'
import gTab from '@/components/common/JOCommonTabMenu.vue'
import gActiveBar from '@/components/common/JOCommonActiveBar.vue'
import gSearchPop from '@/components/common/popup/JOCommonSearchPop.vue'
import gSystemPop from '@/components/common/popup/JOCommonSystemPop.vue'
import gPopup from '@/components/common/layout/JOPopLayout.vue'
import gBtnList from '@/components/common/JOCommonButtonList.vue'
import JOConst from '@/assets/ts/JOConst'
import commonTs from '../public/resource/ts/JOCommon'
import JOFile from '@/assets/ts/JOFile'
import JODalimService from '@/assets/ts/JODalim'
import { postAxios } from './assets/ts/JOCommon'

const app = createApp(App)
app.use(commonTs)
app.use(BootstrapVue3)
app.use(JOConst)
app.use(JOFile)
app.use(JODalimService)

app.use(store).use(router)
app.component('gPreviewImgPop', gPreviewImgPop)
app.component('gSystemPop', gSystemPop)
app.component('gActiveBar', gActiveBar)
app.component('gConfirmPop', gConfirmPop)
app.component('gPopHeader', gPopHeader)
app.component('gPopFooter', gPopFooter)
app.component('gSelectBtn', gSelectBtn)
app.component('gInput', gInput)
app.component('gBtn', gBtn)
app.component('gTab', gTab)
app.component('gSearchPop', gSearchPop)
app.component('gPagenation', gPagenation)
app.component('gPopup', gPopup)
app.component('gBtnList', gBtnList)
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
axios.defaults.headers.common['Access-Control-Allow-Methods'] =
  'GET,POST,PATCH,PUT,DELETE,OPTIONS'
axios.defaults.headers.common['Access-Control-Allow-Headers'] =
  'Origin, Content-Type, X-Auth-Token'
axios.defaults.headers.common['Access-Control-Allow-Credentials'] = true
axios.defaults.headers.common['Content-Type'] = 'application/json;'
axios.defaults.timeout = 20000
axios.defaults.withCredentials = true

app.config.globalProperties.$axios = axios
app.mount('#app')
// window.localStorage.removeItem('systemGoji')

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $gPostAxios: any;
    $JOConst: any;
    $store: any;
    $axios: any;
    $gUploadFile: any;
    $gOpenBoardMain: any;
    $gSaveBoardContents: any;
    $gOpenIntroduceServiceMain: any;
    $setPopPosition: any;
    $changeDateFormat: any;
    $gShowAxiosLoading: any;
    $gConvertToPlain: any;
    $checkUserAuth: any;
    $browserDetect: any;
    $getCurrentDate: any;
    $showSystemMsgPop: any;
  }
}
