<template>
    <div class="JOTargetTabBtn commonSubTitleText">{{ pTabMenu.tabName }}</div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    pTabMenu: {} as any
  }
})
</script>

<style scoped>
.JOTargetTabBtn {
  width: 80px; height: 25px; text-align: center; float: left; cursor: pointer;
}
</style>
