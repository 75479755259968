<template>
  <input
    :disabled="pDisabledYn"
    class="JOCommonInputStyle"
    v-if="pInputType === 'N'"
    v-model="mInputObj.val"
    type="number"
    name=""
    id=""
  />
  <input
    :disabled="pDisabledYn"
    :readonly="pReadonly"
    :placeholder="pPlaceHolder"
    class="JOCommonInputStyle"
    v-else-if="pInputType === 'I'"
    v-model="mInputObj.val"
    type="text"
    name=""
    id=""
  />
  <input
    :disabled="pDisabledYn"
    class="JOCommonInputStyle"
    v-else-if="pInputType === 'P'"
    v-model="mInputObj.val"
    type="password"
    name=""
    id=""
  />
  <select
    :disabled="pDisabledYn"
    class="JOCommonInputStyle"
    v-else-if="pInputType === 'S'"
    v-model="mInputObj.val"
    name=""
    id="selectInput"
    @change="changeInput()"
  >
    <option
      v-for="(opt, index) in pInputObj.selectValueList"
      :key="index"
      :value="opt.value"
    >
      {{ opt.title }}
    </option>
  </select>
  <textarea
    @input="resize"
    ref="commonTextArea"
    :placeholder="pPlaceHolder"
    class="JOCommonInputStyle textarea"
    v-else-if="pInputType === 'T'"
    v-model="mInputObj.val"
    name=""
    id=""
    cols="30"
    rows="10"
  ></textarea>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    pInputObj: {} as any,
    pInputType: {} as any,
    pInputIndex: {} as any,
    pPlaceHolder: {} as any,
    pDisabledYn: Boolean,
    pReadonly: {} as any
  },
  mounted() {
    if (this.pInputType === 'T') {
      const textref = this.$refs.commonTextArea as any
      textref.style.height = '1px'
      textref.style.height = textref.scrollHeight + 'px'
    }
  },
  data() {
    return {
      mInputObj: {} as any
    }
  },
  methods: {
    resize() {
      if (this.pInputType === 'T') {
        const textref = this.$refs.commonTextArea as any
        textref.style.height = '1px'
        textref.style.height = textref.scrollHeight + 'px'
      }
    },
    changeInput() {
      const selectBox = document.getElementById('selectInput') as any
      const selectedOption = {
        name: selectBox.options[selectBox.selectedIndex].text,
        key: selectBox.options[selectBox.selectedIndex].value
      }
      console.log('changed selectOption value ======', selectedOption)
      if (this.mInputObj.changeCallbackFn) {
        this.mInputObj.changeCallbackFn(selectedOption)
      }
    }
  },
  watch: {
    pInputObj: {
      immediate: true,
      handler(val) {
        if (!val) return
        this.mInputObj = val
      }
    }
  }
})
</script>

<style scoped>
select {
  border: none;
}
.JOCommonInputStyle {
  border: 1px solid #aaa;
  border-radius: 5px;
  min-width: 50px;
  width: 50px;
  height: 35px;
  box-sizing: border-box;
}
textarea {
  resize: none;
  min-height: 80px;
  overflow: hidden;
}
</style>
