<template>
    <div class="JOPopHeader">
        <p class="commonTitleText">{{pPopTitle}}</p>
        <img v-if="!pJosaDetailPageYn" class="JOPopClose" src="@/assets/images/common/JOCloseIcon.svg" alt="" @click="pClosePop">
    </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    pPopTitle: {},
    pJosaDetailPageYn: {},
    pClosePop: Function as any
  }
})
</script>

<style scoped>
.JOPopHeader {
    width: 100%; height: 50px; float: left; border-bottom: 1px solid #CCC; box-shadow: 0px 7px 9px -9px #00000036; display: flex; justify-content: center; align-items: center; position: relative;
}
.JOPopClose {
    position: absolute; right: 20px; width: 40px; cursor: pointer;
}
</style>
