<template>
  <div class="JOPopBackShadow" @click="pPopInfo.closeFn"></div>
  <component
    :is="'layout'"
    class="JOPopLayoutWrap JOShadow"
    :class="pZIndex ? 'JOIndex' : ''"
    :style="reponsiveSize"
  >
    <header v-if="pPopInfo.noHeader ? false : true">
      <button class="backBtn" @click="pPopInfo.closeFn">
        <img src="resource/images/common/JOCloseIcon.svg" alt="닫기" />
      </button>
      <h3 class="popTitle commonHeaderText">{{ pPopInfo.title }}</h3>
    </header>

    <header v-else>
      <h3 class="JOPopNoHeader popTitle commonHeaderText">
        {{ pPopInfo.title }}
      </h3>
    </header>

    <slot />

    <footer>
      <gBtnList :pBtnList="pPopInfo.btnList" />
    </footer>
  </component>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

/**
 * props 전달 예시
    {
      title: '팝업 제목',
      closeFn: () => {},
      size: { w: 600px, h: 100% },
      btnList: [
        { btnTitle: "신고하기", pointYn: false, onClickFn: () => {} },
        { btnTitle: "닫기", pointYn: false, onClickFn: () => {}}
      ],
      isFull?: boolean,
      isConfirm?: boolean,
      noHeader?: boolean,
      isBig?: boolean
    }
 */

export default defineComponent({
  props: {
    pPopInfo: Object as any,
    pZIndex: Number
  },
  data() {
    return {
      mScreenWidth: window.innerWidth,
      mScreenHeight: window.innerHeight
    }
  },
  computed: {
    reponsiveSize() {
      if (this.mScreenWidth >= 750) {
        return {
          width: this.pPopInfo.size.w,
          height: this.pPopInfo.size.h
        }
      } else if (this.pPopInfo.isFull) {
        return {
          width: '100%',
          height: '100%'
        }
      } else if (this.pPopInfo.isConfirm) {
        return {
          width: (this.mScreenWidth / 10) * 9 + 'px',
          height: (this.mScreenHeight / 10) * 4 + 'px'
        }
      } else {
        return {
          width: (this.mScreenWidth / 10) * 9 + 'px',
          height: (this.mScreenHeight / 10) * 6 + 'px'
        }
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      const wrapDom: any = document.querySelector('html')
      wrapDom.style.overflow = 'hidden'
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeUnmount() {
    const wrapDom: any = document.querySelector('html')
    wrapDom.style.overflow = 'hidden auto'
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      this.mScreenWidth = window.innerWidth
    }
  }
})
</script>

<style scoped>
.JOIndex {
  z-index: 10000 !important;
}
.JOPopBackShadow {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000026;
  position: fixed;
  z-index: 599;
}
.JOPopLayoutWrap {
  z-index: 600;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  background-color: #fff;
  border: 2px solid #dcdcdc;
  border-radius: 5px;

  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.15);

  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

header {
  position: relative;
  padding: 20px 35px;
  display: flex;
  align-items: center;
}

.JOPopNoHeader {
  margin: 0 auto;
  font-weight: bold;
  color: #ffab3a;
}
.popTitle {
  text-align: center;
  margin-bottom: 0;
}

.backBtn {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);

  cursor: pointer;
}
.backBtn > img {
  width: 40px;
}
footer {
  padding: 15px 0;
  display: flex;
  gap: 10px;
  justify-content: center;
}
</style>
