<template>
    <template v-if="!pToggleYn" >
        <gBtn :style="pBtnSize? `width: ${pBtnSize}%; margin-bottom: ${pMBottom}; margin-left: 0px !important;`:''" class="commonSmallText" :class="index !== 0? 'mLeft-10' : ''" v-for="(btn, index) in pBtnList" :key="index" @click="changeBtnEvnt(index, btn)" :pPointYn="selectedBtnIndex === index" :pBtnTitle="btn.title"/>
    </template>
    <div v-else-if="pToggleYn" class="JOToggleWrap">
        <div @click="changeBtnEvnt(index, btn)" class="fl JOToggleBtn commonSmallText" v-for="(btn, index) in pBtnList" :class="selectedBtnIndex === index? 'JOPointBtn' : 'JONormalBtn'" :key="index" :style="'width: '+ 100 / pBtnList.length + '%;'" >{{btn.title}}</div>
    </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    pBtnList: {
      default: null as any
    },
    pInputIndex: {},
    pInputObj: {
      default: null as any
    },
    pToggleYn: {},
    pChangeInput: Function,
    pBtnSize: {
      default: null as any
    },
    pMBottom: {
      default: 0 as any
    }
  },
  data () {
    return {
      selectedBtnIndex: 0
    }
  },
  watch: {
    pInputObj: {
      immediate: true,
      handler (val) {
        if (val.val !== undefined && val.val !== null && val.val !== '') {
          if (this.pBtnList && this.pBtnList.length > 0) {
            const idx = this.pBtnList.findIndex((item : any) => item.value === val.val)
            console.log(val)
            if (idx !== -1) {
              this.selectedBtnIndex = idx
            }
          }
        }
      },
      deep: true
    }
  },
  methods: {
    changeBtnEvnt (index : number, btn: any) {
      this.selectedBtnIndex = index
      const inputObj = this.pInputObj
      inputObj.val = this.pBtnList[this.selectedBtnIndex].value
      if (this.pChangeInput) {
        this.pChangeInput(inputObj, this.pInputIndex)
      }
      if (btn.onclickCallBack) {
        btn.onclickCallBack(btn.value)
      }
    }
  }
})
</script>

<style scoped>
.JOCommonInputStyle {
    border: 1px solid #aaa;
    border-radius: 5px;
    min-width: 30px;
    height: 35px;
    box-sizing: border-box;
}
.JOPointBtn {
    background-color: #0b4180;
    color: #FFFFFF;
}

.JONormalBtn {
    background-color: #FFFFFF;
    border-right: 1px solid #aaa;
}
.JONormalBtn:last-child {
    border-right: none!important;
}
.JOToggleBtn {
    padding: 0 3px;
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.JOToggleWrap {
    display: flex; align-items: center; border-radius: 5px; height: 30px; border: 1px solid #aaa; overflow: hidden;
}
</style>
