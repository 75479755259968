<template>
    <div class="JOConfirmPopWrap" :style="mPositionText">
        <div class="JOCommonConfirmHeader commonHeaderText p-10 pLeft-20">
            <img class="fl" style="height: 100%;" src="../../../assets/images/logo/JOMainHeaderLogo.png" alt="">
            <img @click="pClosePop" class="fr cursorP" style="width: 40px;" src="../../../assets/images/common/JOCloseIcon.svg" alt="">
        </div>
        <div class="JOCommonConfirmBody commonNormalText textCenter" v-html="pPopBodyText"></div>
        <div class="JOCommonConfirmFooter">
            <gPopFooter v-if="pBtnList && pBtnList.length > 0" :pBtnList="pBtnList" />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
// import { setPopPosition } from '@/assets/ts/JOCommon'
export default defineComponent({
  data () {
    return {
      mPositionText: '',
      mPopSize: { popWidth: 500, popHeight: 350 }
    }
  },
  props: {
    pPopHeaderText: {},
    pPopBodyText: {},
    pClosePop: Function as any,
    pTimerFunction: Function,
    pBtnList: [] as any
  },
  mounted () {
    this.mPositionText = this.$setPopPosition(this.mPopSize.popWidth, this.mPopSize.popHeight)
    window.addEventListener('resize', () => {
      this.mPositionText = this.$setPopPosition(this.mPopSize.popWidth, this.mPopSize.popHeight)
    })
    if (this.pTimerFunction) {
      const timerFunction = this.pTimerFunction
      setTimeout(() => {
        timerFunction()
      }, 2000)
    }
  }
})
</script>

<style scoped>
.JOConfirmPopWrap {
    position: fixed;
    z-index: 99;
    background: white;
    box-shadow: rgb(68 68 68) 0px 0px 4px 0px;
    border: 2px solid rgb(157, 157, 157);
    border-radius: 10px;

}
.JOCommonConfirmHeader {
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #ccc;
}
.JOCommonConfirmHeader p{
    float: left;
    width: 100%;
    text-align: center;
}
.JOCommonConfirmBody {
  width: 100%;
  padding: 10px 20px;
  min-height: calc(100% - 60px - 60px);
  float: left;
  /* text-align: center; */
  padding-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.JOCommonConfirmFooter {
  width: 100%; height: 60px; float: left;
}
</style>
