<template>
  <router-view />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import '@/assets/css/JOCommon.css'
import '@/assets/css/JOReset.css'

export default defineComponent({
  methods: {}
})
</script>
