<template>
    <div class="btnStyle commonSmallText fontBold" :class="pPointYn? 'pointBtn' : ''" v-html="pBtnTitle"></div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    pBtnTitle: {},
    pPointYn: {}
  },
  data () {
    return {

    }
  }
})
</script>
<style scoped>
.btnStyle {
    min-width: 30px; height: 30px; display: flex; justify-content: center; align-items: center;
    border-radius: 5px;
    border: 1px solid #aaa;
    background: #FFFFFF;
    padding: 0 4px;
    cursor: pointer;
}
.pointBtn {
    background: #0b4180;
    border: none;
    color: #FFFFFF;
}
</style>
